
import { io } from "socket.io-client";
import axios from 'axios';
import store from './store'


var token = store.state.user.access_token;
var socket = io(`${process.env.VUE_APP_API}`, {
  reconnectionDelayMax: 10000,
  auth: {
    token: token,
    front_build: process.env.VUE_APP_BuildVersion
  },
   transports: ["websocket"] 
});



function refresh() {
  token = store.state.user.access_token;
  socket = io(`${process.env.VUE_APP_API}`, {
    reconnectionDelayMax: 10000,
    auth: {
      token: token,
      front_build: process.env.VUE_APP_BuildVersion
    },
   transports: ["websocket"] 
  });
}




async function STATUS() {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/database/status`);
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function OFFSETS() {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/database/offsets`);
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function DOWNLOAD_APP(app) {

  try {

    const response = await axios.get(`${process.env.VUE_APP_API}/api/apps/${app.name}`, { responseType: 'blob', headers: { authorization: token } });

    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');

    link.href = href;

    link.setAttribute('download', `${app.realname}.zip`); //or any other extension

    document.body.appendChild(link);

    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    return true;
  } catch {
    return false
  }


}
async function DELETE_ACCOUNT() {
  try {
    const response = await axios.delete(`${process.env.VUE_APP_API}/api/profile`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function GET_AD() {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/advertisement`);
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function GET_APPS() {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/apps`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function GET_GAMES(offset, actual, query) {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/games`, { params: { offset, actual, query }, headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function A_GET_GAME(id) {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/admin/game/${id}`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function GET_MY_GAMES(offset, query) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/games/my`, { params: { offset, query }, headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function GET_WARDS(game, offset, query) {
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/wards/`, { game, offset, query }, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function GET_PAY(game, EntryData) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/pay/${game}`, { EntryData, }, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function GET_ALIASES() {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/database/aliases/`);
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function GET_MANAGEMENT(game) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/management/${game}`,  { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function GET_PUBLIC_LIST(game) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/game/${game}/list/`,{ headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

// this.game._id, this.edit._id, this.edit
async function EDIT_PLAYER_MANAGEMENT(game, player, data) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.put(`${process.env.VUE_APP_API}/api/management/${game}/player/`, { player, data }, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

//ОСТАНОВИЛСЯ НА РЕФАКТОРЕ ЗДЕСЬ!

async function A_CREATE_GAMES(game) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/admin/games/new/`, { game }, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function A_CREATE_TEMPLATE(template) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/admin/templates/new/`, { template }, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

function GenerateError(error) {
  console.error(error)
  if (error?.response?.data?.error)
    return error.response.data
  else return { error: "Не удалось выполнить запрос" }
}

async function A_SAVE_GAME(id, game, noConsequences) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/admin/game/${id}`, { game, noConsequences }, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function A_SAVE_TEMPLATE(id, template) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/admin/template/${id}`, { template }, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}



async function A_GET_TEMPLATES() {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/admin/templates`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function A_GET_TEMPLATE(id) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/admin/templates/${id}`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function A_DELETE_TEMPLATE(id) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.delete(`${process.env.VUE_APP_API}/api/admin/template/${id}`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function A_DELETE_GAME(id) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.delete(`${process.env.VUE_APP_API}/api/admin/game/${id}`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function MAKE_REPORT(data) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/report/`, { data }, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function REGISTER_WARD(game, data) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/game/${game}/ward`, data, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function EDIT_PROFILE(data) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.put(`${process.env.VUE_APP_API}/api/profile`, data, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function ME(){
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/profile`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function UNREGISTER_WARD(game, data) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.delete(`${process.env.VUE_APP_API}/api/game/${game}/ward`, { headers: { authorization: token }, data});
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function PROMO(type) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.put(`${process.env.VUE_APP_API}/api/profile/promo`, {type }, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }


}


async function CREATE_PROFILE(data) {

  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/profile/create`, data);
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}



async function CREATE_PROFILE_PRE_VERIFY(data) {

  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/profile/create/pre-verify`, data);
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function PROFILE_EMAIL_VERIFY(data) {

  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/profile/email-verification`, data,  { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function FORGET_PASSWORD_SEND(data) {

  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/profile/restore/send`, data);
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}


async function FIND_TEAM(data) {
  // if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/database/team`, data);
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }

}

async function LOGIN(data) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_API}/api/profile/login`, data);
      return response.data;
    } catch (error) {
      return GenerateError(error);
    }
}


async function FINISH_RESTORE_PASSWORD(token, data) {
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/profile/restore/finish/${token}`, data);
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}


async function MAKE_COACH_OWNER(ward, coach) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/ward/${ward}/owner`, {coach}, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function FIND_COACH(data) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/database/coach`, data, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}


// 


async function EDIT_WARD(ward, data) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.put(`${process.env.VUE_APP_API}/api/wards/${ward}`, {data}, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function CREATE_WARD(data) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/wards/new`, {data}, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function ADD_COACH(ward, coach) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/ward/${ward}/coaches/${coach}`, {}, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}
async function REMOVE_COACH(ward, coach) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.delete(`${process.env.VUE_APP_API}/api/ward/${ward}/coaches/${coach}`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function DELETE_WARD(ward) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.delete(`${process.env.VUE_APP_API}/api/wards/${ward}/`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}



async function REGISTER_REFEREE(game) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.post(`${process.env.VUE_APP_API}/api/game/${game}/referee`, {}, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function UNREGISTER_REFEREE(game) {
  if (!store.state?.user?.access_token) return;
  try {
    const response = await axios.delete(`${process.env.VUE_APP_API}/api/game/${game}/referee`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}

async function GAME_GET(id, type) {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API}/api/game/${id}?type=${type}`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return GenerateError(error);
  }
}




export default {
  _: {
    refresh
  },
  rest: {
    OFFSETS,
    GET_GAMES,
    GET_ALIASES,
    GET_WARDS,
    GET_MY_GAMES,
    GET_PAY,
    GET_APPS,
    DOWNLOAD_APP,
    GET_PUBLIC_LIST,
    GET_MANAGEMENT,
    EDIT_PLAYER_MANAGEMENT,
    MAKE_REPORT,
    GET_AD,
    DELETE_ACCOUNT,
    PROFILE: {
      CREATE_PROFILE,
      ME,
      LOGIN,
      EDIT_PROFILE,
      CREATE_PROFILE_PRE_VERIFY,
      PROMO,
      PROFILE_EMAIL_VERIFY,
      FORGET_PASSWORD_SEND,
      FINISH_RESTORE_PASSWORD,
    },
    WARDS: {
      MAKE_COACH_OWNER,
      ADD_COACH,
      EDIT_WARD,
      CREATE_WARD,
      REMOVE_COACH,
      DELETE_WARD,
    },
    DATABASE: {
      FIND_TEAM,
      FIND_COACH,
      STATUS,
    },
    GAMES: {
      REGISTER_WARD,
      UNREGISTER_WARD,
      REGISTER_REFEREE,
      GET: GAME_GET,
      UNREGISTER_REFEREE,
    },
    ADMIN: {
      GET_TEMPLATE: A_GET_TEMPLATE,
      CREATE_TEMPLATE: A_CREATE_TEMPLATE,
      CREATE_GAMES: A_CREATE_GAMES,
      GET_TEMPLATES: A_GET_TEMPLATES,
      GET_GAME: A_GET_GAME,
      DELETE_GAME: A_DELETE_GAME,
      SAVE_GAME: A_SAVE_GAME,
      SAVE_TEMPLATE: A_SAVE_TEMPLATE,
      DELETE_TEMPLATE: A_DELETE_TEMPLATE,
    }
  },
  socket: socket,
}